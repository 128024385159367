.mainWrapper {
  display: flex;
  border-top: 1px solid var(--color-divider-on-dark);
}

.docsCard {
  border: 1px solid var(--color-divider-on-dark);
  border-radius: 8px;
  padding: 14px 16px;
  margin: 0 !important;
  gap: 4px;
  display: flex;
  flex-direction: column;
  transition: border 0.2s ease;
  color: var(--copy-on-dark);
  &:hover {
    border: 1px solid var(--color-yellow-300);
    color: var(--copy-on-dark);
  }
}

.docsCardGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 34px;
}

@media screen and (max-width: 600px) {
  .docsCardGroup {
    grid-template-columns: 1fr;
  }
}

.docsCardPara {
  p {
    margin: 0 !important;
  }
}

.leftSection {
  display: flex;
  flex-direction: column;
  flex: 0 0 300px;
  position: sticky;
  height: calc(100vh - 54px);
  top: 0px;
  z-index: 5;
  max-width: 300px;
}

.listItem {
  margin-left: 20px;
}

.listItem > * {
  display: inline;
}

.active {
  border-radius: 4px;
  background-color: var(--color-divider-on-dark);
}

.emptyContent {
  opacity: 0.7;
  color: var(--copy-on-dark);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.searchResultCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--size-small);
  padding: var(--size-small) var(--size-medium);
  cursor: pointer;
  border-radius: var(--border-radius);
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-divider-on-dark);
  }

  .content {
    opacity: 0.7;
    color: var(--copy-on-dark);
    border-left: 4px solid var(--color-divider-on-dark);
    padding-left: var(--size-medium);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
  }

  .resultTitle {
    color: var(--copy-on-dark);
  }

  .highlightedText {
    color: var(--color-selected-light);
    background-color: transparent;
  }
}

.tocIcon {
  width: var(--size-large) !important;
  height: var(--size-large) !important;
  transition: color 0.2s ease;
  color: var(--color-copy-on-light);
  stroke: var(--color-divider-on-dark);
  flex: 0 0 var(--size-large);
}

.tocRow {
  display: flex;
  align-items: center;
  padding: 4px 0;
  color: var(--copy-on-dark);

  &:hover {
    border-radius: var(--border-radius);
    background: #30294e4d;
    color: var(--copy-on-dark);
    cursor: pointer;

    .tocIcon {
      color: var(--color-copy-on-dark);
      stroke: var(--color-copy-on-dark);
    }

    .tocChild {
      opacity: 1;
      color: var(--color-copy-on-dark);
    }
  }
}

.tocChild {
  color: var(--darker-copy-on-dark);
}

.tocItem {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.5rem !important;
}

.tocItem a {
  color: var(--copy-on-dark) !important;
  font-weight: 400;
}

.tocItemChevronClosed {
  transform: rotate(270deg);
}

.tocItemOpen a,
.tocItemOpen {
  color: var(--color-copy-on-dark) !important;
  font-weight: 500;
}

.tocItemCurrent a,
.tocItemCurrent {
  color: var(--color-copy-on-dark) !important;
  stroke: var(--color-copy-on-dark);
  opacity: 1 !important;
}

.tocChildren {
  display: flex;
  flex-direction: row;
}

.tocChildrenLineWrapper {
  margin-left: 11px;
  width: 2px;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
}

.tocChildrenLine {
  background-color: var(--brand-accent-light);
  opacity: 30%;
  width: 2px;
  height: 100%;
}

.tocChildrenContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pageTitle {
  margin: 0 0 var(--size-medium) !important;
}

.sdkPageTitle {
  font-size: 26px;
  line-height: 36px;
}

.quickStartSubtext {
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px !important;
    margin: 0 !important;
    color: var(--copy-on-dark);
  }
}

.contentRender {
  h4,
  h5 {
    scroll-margin-top: var(--size-large);
    margin: var(--size-large) 0 !important;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-copy-on-light);
      text-decoration-thickness: 1px;
      text-underline-offset: var(--size-xSmall);
      cursor: pointer;
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px !important;
    margin: var(--size-medium) 0;
    color: var(--copy-on-dark);
  }

  img {
    width: 100%;
    border-radius: var(--border-radius);
  }
}

.sdkRightColumn {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px !important;
    margin: var(--size-medium) 0 !important;
    color: var(--copy-on-dark);
  }
}

.sdkLeftColumn {
  h4 {
    scroll-margin-top: var(--size-large);
    margin: var(--size-xSmall) 0 !important;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color-copy-on-light);
      text-decoration-thickness: 1px;
      text-underline-offset: var(--size-xSmall);
      cursor: pointer;
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px !important;
    margin: var(--size-medium) 0 !important;
    color: var(--copy-on-dark);
  }

  ul,
  ol,
  li {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px !important;
  }

  img {
    width: 100%;
    border-radius: var(--border-radius);
  }
}

.inlineCodeBlock {
  border-radius: var(--space-unit);
  background-color: var(--color-pseudo-black);
  padding: 2px 6px;
  border: 1px var(--color-divider-on-dark) solid;
  color: var(--copy-on-dark);
}

h4:hover .headingCopyIcon,
h5:hover .headingCopyIcon {
  display: inline-flex;
}

.headingCopyIcon {
  vertical-align: middle;
  align-items: center;
  margin-top: -4px;
  color: var(--color-copy-on-light);
  display: none;

  svg {
    width: var(--size-large);
    height: var(--size-large);
    margin-left: var(--size-small);
  }
}

.docSelect {
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--color-primary-background);
  color: var(--color-copy-on-dark);
  padding: var(--size-xxSmall) var(--size-xSmall);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .docSelectText {
    display: flex;
    align-items: center;
  }

  .tocIcon {
    display: flex;
    align-items: center;
    padding: 0 var(--size-xxSmall);
    color: var(--color-copy-on-light);
    stroke: none !important;
  }

  .chevronDown {
    padding: 0;
  }

  span {
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    border-radius: var(--border-radius);
    background: var(--color-divider-on-dark);
    color: var(--copy-on-dark);
    cursor: pointer;

    span {
      color: var(--color-white);
    }

    .tocIcon {
      color: var(--color-white);
      stroke: var(--color-white);
    }
  }
}

.docSelectList {
  position: absolute;
  top: 32px;
  background-color: var(--color-primary-background);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-divider-on-dark);
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  z-index: 10;
  padding: var(--size-xSmall);

  .tocRow {
    .tocChild {
      color: var(--color-copy-on-dark);
      display: flex;
    }
  }

  .tocIcon {
    display: flex;
    align-items: center;
    padding: 0 var(--size-xxSmall);
    stroke: none !important;
  }
}

.contentInnerBar {
  position: sticky;
  width: 100%;
  top: 0;
  min-height: 54px;
  display: flex;
  align-items: stretch;
  border-top: 1px solid var(--color-divider-on-dark);
  background-color: var(--color-primary-background);
  z-index: 10;
}

.leftInner {
  position: relative;
  padding: var(--size-small) var(--size-medium);
  display: flex;
  border-bottom: 1px solid var(--color-divider-on-dark);
  align-items: center;
}

.centerInner {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0 !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
  padding: 8px var(--size-large);
  align-items: center;
  border-left: 1px solid var(--color-divider-on-dark);
}

.searchContainer {
  width: 360px;
  position: relative;
}

.searchDiv {
  position: absolute;
  width: 100%;
  max-width: 360px;
  margin-top: var(--size-xxSmall);
  left: 0;
  background: var(--color-primary-background);
  border: 1px solid var(--color-divider-on-dark);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  border-radius: var(--border-radius);
  padding: var(--size-xSmall);
  max-height: 60vh;

  ul {
    width: 100%;

    &::-webkit-scrollbar {
      width: var(--size-xSmall);
      height: var(--size-xSmall);
    }

    &::-webkit-scrollbar-track {
      background: var(--color-primary-background);
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: var(--size-medium);
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: var(--divider-on-dark);
      }
    }
  }

  .spinner {
    margin: var(--size-medium);
  }
}

.codeBlock {
  background-color: var(--color-primary-500);
  border-radius: 20px;
  border: 1px solid var(--color-divider-on-dark);
  box-shadow: 0px 36px 120px rgba(108, 55, 244, 0.1);
  text-align: left;
  margin-top: var(--size-medium);
  margin-bottom: var(--size-medium);
  padding-right: 25px;
  overflow: hidden;
  position: relative;

  .codeBlockTopper {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    height: 65px;
    width: 100%;
  }

  .codeCopyIcon {
    position: absolute;
    top: var(--size-medium);
    right: var(--size-medium);
    cursor: pointer;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    transition: all 0.2s ease;

    &.active {
      background-color: var(--color-divider-on-dark);
    }

    img {
      opacity: 0.5;
    }

    &:hover {
      background-color: var(--color-divider-on-dark);

      img {
        opacity: 1;
      }
    }
  }

  > span {
    background-color: transparent !important;
    padding: var(--size-large);
    padding-right: 50px;
    border-radius: var(--border-radius) !important;
    white-space: pre !important;
    font-size: 16px;

    &::-webkit-scrollbar {
      width: var(--size-xSmall);
      height: var(--size-xSmall);
    }

    &::-webkit-scrollbar-track {
      background: var(--color-primary-background);
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: var(--size-medium);
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: var(--divider-on-dark);
      }
    }

    @media screen and (max-width: 600px) {
      font-size: 14px !important;
    }
  }
}

.contentSection {
  display: flex;
  flex: 1 1 auto;
  width: calc(100% - 300px);
  position: relative;
}

.centerSection {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 850px;
  min-width: 0;
  padding: var(--size-xLarge) var(--size-4xLarge) var(--size-4xLarge);
  border-left: 1px var(--color-divider-on-dark) solid;
}

.sdkCenterSection {
  max-width: 1500px;
}

.quickStartCenterSection {
  max-width: 1500px;
}

.rightSection {
  display: flex;
  flex: 0 0 320px;
  flex-direction: column;
  position: sticky;
  padding: var(--size-xLarge) var(--size-large);
  top: 0px;
  height: calc(100vh - 80px);
}

.headingSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.docLink {
  color: var(--color-gray-400);
  cursor: pointer;
  transition: color 0.2s ease;
}

.docLink:hover {
  color: var(--color-white);
}

.rightBarWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.socialItem {
  padding: 8px 12px 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.sdkSocialItem {
  padding: 6px 12px 6px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #30294e;
  border-radius: 8px;
}

.resourcesSideBar {
  border: 1px solid var(--divider-on-dark);
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
}

.resourcesMobile {
  display: none;
  border: 1px solid var(--divider-on-dark);
  flex-direction: column;
  border-radius: var(--border-radius);
  max-width: 400px;
}

.pageContentTable {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;

  border: 1px solid var(--color-divider-on-dark);
  border-radius: var(--border-radius);
}

.pageNavigateRow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-divider-on-dark);
  padding: var(--size-large) 0 var(--size-small);
}

.pageNavigate {
  display: flex;
  align-items: center;
  color: var(--color-copy-on-dark);

  svg {
    opacity: 0.5;
    width: var(--size-large);
    height: var(--size-large);
  }

  &:hover {
    cursor: pointer;
    color: var(--color-copy-on-dark);

    svg {
      opacity: 1;
    }
  }
}

.pageContentTitle {
  gap: var(--size-small);
  width: 100%;
  display: flex;
  padding: var(--size-small) var(--size-medium);
  border-bottom: 1px solid var(--color-divider-on-dark);

  .pageContentIcon {
    width: var(--size-small);
    flex: 0 0 var(--size-small);
  }
}

.pageContentList {
  width: 100%;
  padding: 0 var(--size-xSmall);

  ul {
    list-style-position: outside;
    width: 100%;
    margin: 0;

    .pageContentBullet {
      width: var(--size-small);
    }

    li {
      margin: var(--size-xSmall) 0;
      color: var(--copy-on-dark);
      padding: 0 var(--size-small);
      opacity: 0.7;
    }
    a {
      color: var(--copy-on-dark);
      font-weight: 400;
      display: flex;
      gap: var(--size-xxSmall);
    }

    .active {
      opacity: 1;

      a {
        color: var(--color-white);
      }
    }
  }
}

.breadcrumb {
  margin: var(--size-medium) 0;
  color: var(--darker-copy-on-dark);
  font-weight: 400;
}

.breadcrumb a {
  color: var(--darker-copy-on-dark);
  font-weight: 400;

  &:hover {
    color: var(--color-copy-on-dark);
  }
}

.tocMenu {
  display: none;
}

.tocMenuLarge {
  overflow: auto;
  overflow-x: hidden;
  padding: var(--size-small) var(--size-large);
  scrollbar-color: var(--divider-on-dark) var(--color-primary-background);

  &::-webkit-scrollbar {
    width: var(--size-xSmall);
    height: var(--size-xSmall);
  }

  &::-webkit-scrollbar-track {
    background: var(--color-primary-background);
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: var(--size-medium);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--divider-on-dark);
    }
  }
}

.customComponent {
  font-family: 'Poppins';
  white-space: normal;

  span {
    font-size: 16px;
    line-height: 24px;
  }
}

.callout {
  margin-top: 18px;
  display: flex;
  background: var(--color-divider-on-dark);
  border: 1px solid var(--color-divider-on-dark);
  border-radius: var(--size-medium);
  padding: var(--size-medium);
  white-space: normal;
  gap: var(--size-small);

  h1,
  h2,
  h3,
  h4 {
    color: var(--color-white);
    font-size: var(--size-medium);
    line-height: 26px !important;
    letter-spacing: 0;
  }

  p {
    color: var(--color-copy-on-dark);
    font-family: 'Poppins';
    font-size: var(--size-medium);
    line-height: 26px !important;
    margin: 0 !important;
  }

  .calloutIcon {
    flex: 0 0 40px;
    width: 40px;

    .icon {
      display: flex;
      width: 40px;
      height: 40px;
      border: 1px solid var(--color-copy-on-light);
      align-items: center;
      justify-content: center;
      border-radius: var(--border-radius);

      svg {
        color: var(--color-copy-on-dark);
      }
    }
  }

  .calloutContent {
    flex: 1 1 auto;
  }
}

.sdkDocSection {
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 40px;
  gap: var(--size-xxLarge);
  border-bottom: 1px solid var(--divider-on-dark);

  .sdkLeftColumn {
    flex: 1 0 calc(50% - var(--size-xxLarge));
  }

  .sdkRightColumn {
    padding-top: var(--size-4xLarge);
    position: sticky;
    align-self: flex-start;
    top: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  h4 {
    font-size: 26px;
    line-height: 40px !important;
    font-weight: 600 !important;
  }

  h6 {
    margin: var(--size-small) 0 !important;
  }

  p {
    margin: var(--size-small) 0 var(--size-large) !important;
  }

  ul,
  ol,
  li {
    color: var(--color-copy-on-dark);
  }

  ul {
    list-style-type: disc;
    margin-left: var(--size-small);
  }

  ol {
    list-style-type: decimal;
    margin-left: var(--size-small);
  }
}

.methodParameter {
  padding: var(--size-medium) 0;
  border-top: 1px solid var(--color-divider-on-dark);

  h5 {
    display: flex;
    font-family: 'Fira Code' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    align-items: center;
    gap: 0 var(--size-xSmall);
    margin: var(--size-xSmall) 0 !important;
    flex-wrap: wrap;

    &:hover {
      text-decoration: none;
    }

    code {
      color: var(--color-copy-on-dark);
      font-weight: 400;
      font-size: 14.5px;
      line-height: 24px;
      background-color: rgba(29, 10, 38, 1);
      border: 1px solid var(--color-divider-on-dark);
    }
  }

  p {
    color: var(--darker-copy-on-dark);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px !important;
    margin: var(--size-xSmall) 0 !important;
  }
}

.innerParameterContainer {
  margin-top: var(--size-medium);
  border: 1px solid var(--color-divider-on-dark);
  border-radius: var(--size-small);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .innerParameterHeading {
    background: #30294e;
    padding: var(--size-xxSmall) var(--size-small);
    font-size: 14px;
    line-height: 22px;
    color: var(--color-copy-on-dark);
  }

  .methodParameter {
    padding: var(--size-xSmall) var(--size-small);
  }

  p {
    font-size: 14px;
  }
}

@supports (-webkit-hyphens: none) {
  .contentRender {
    h4,
    h5 {
      padding-top: var(--size-large);
      margin-top: calc(-1 * var(--size-large));
    }
  }
}

@media screen and (max-width: 1000px) {
  .resourcesMobile {
    display: flex;
  }

  .mainWrapper {
    flex-direction: column;
  }

  .searchDiv {
    max-width: none;
  }

  .contentRender,
  .sdkRender {
    h4,
    h5 {
      margin: 0 !important;
    }
  }

  .leftSection {
    background-color: var(--color-primary-background);
    overflow: initial;
    flex: 0 0 auto;
    height: auto;
    border-bottom: 1px solid var(--divider-on-dark);
    padding: var(--size-large) var(--size-large);
    position: relative;
    max-width: none;
    top: 0;
  }

  .rightSection {
    display: none;
  }

  .contentSection {
    width: 100%;
  }

  .centerSection {
    padding: var(--size-large) var(--size-xLarge) var(--size-4xLarge);
    border: 0;
  }

  .leftInner {
    padding: 0;
  }

  .tocMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .tocMenuLabel {
      display: flex;
      align-items: center;
    }
  }

  .tocContents {
    padding-left: var(--size-small);
  }

  .tocMenuLarge {
    display: none;
  }

  .codeBlock {
    font-size: var(--size-small);
  }

  .searchContainer {
    min-width: 0;
    width: 100%;
  }

  .sdkDocSection {
    flex-direction: column;
    gap: var(--size-small);

    .sdkLeftColumn {
      flex: 1 1 auto;
      width: 100%;

      h6 {
        font-size: 20px;
      }
    }

    .sdkRightColumn {
      padding-top: 0;
      width: 100%;
      position: relative;
      flex: 1 1 auto;
      max-width: none;

      h6 {
        font-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .inlineCodeBlock {
    word-break: break-word;
  }

  .leftSection {
    top: 0;
  }

  .centerSection {
    padding: var(--size-large) var(--size-medium) var(--size-4xLarge);
  }

  .contentInnerBar {
    flex-direction: column;
    gap: var(--size-small);
    height: auto;
    padding: var(--size-medium);

    .leftInner {
      flex: 0 0 auto;
      width: 100%;
    }

    .centerInner {
      height: auto;
      padding: 0;
      border: 0;
    }

    .docSelectText {
      margin-left: var(--size-xxSmall);
      gap: var(--size-xSmall);
    }
  }

  .searchContainer {
    width: 100%;
  }
}
